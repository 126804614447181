import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import '../css/Desserts.css';
import '../styles.css';

// Set worker source using CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Desserts() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [error, setError] = useState(null);
    const [scale, setScale] = useState(1.2);
    const [currentSection, setCurrentSection] = useState(null);
    const scrollPositionRef = useRef(0);

    // Update sections to include full menu
    const sections = {
        full: {
            start: 1,
            end: 25,
            displayStart: 1,
            displayEnd: 25
        },
        cakes: {
            start: 2,
            end: 22,
            displayStart: 1,
            displayEnd: 20
        },
        pastries: {
            start: 23,
            end: 25,
            displayStart: 1,
            displayEnd: 3
        }
    };

    // Add scale handling for mobile
    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 768;
            setScale(isMobile ? 1.5 : 0.9);
        };

        handleResize(); // Set initial scale
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Add touch handling for mobile
    useEffect(() => {
        const container = document.querySelector('.container');
        if (container && window.innerWidth <= 768) {
            container.style.touchAction = 'manipulation';
            return () => {
                container.style.touchAction = 'manipulation';
            };
        }
    }, []);

    useEffect(() => {
        try {
            // Use the imported PDF directly
        } catch (error) {
            console.error('Error loading PDF:', error);
            setError(error);
        }
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setError(null);
    }

    function onDocumentLoadError(error) {
        console.error('PDF Load Error:', error);
        setError(error);
    }

    const changePage = useCallback((newPage, e) => {
        e.preventDefault();
        if (!currentSection) {
            setPageNumber(newPage);
            return;
        }
        const section = sections[currentSection];
        const actualPage = section.start + (newPage - 1);
        
        // Store current scroll position before changing page
        const currentScroll = window.scrollY;
        
        setPageNumber(actualPage);
        
        // Restore scroll position after a brief delay
        if (window.innerWidth <= 768) {
            setTimeout(() => {
                window.scrollTo(0, currentScroll);
            }, 100);
        }
    }, [currentSection]);

    // Add event listener to store scroll position when user scrolls
    React.useEffect(() => {
        const handleScroll = () => {
            scrollPositionRef.current = window.scrollY;
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const getCurrentDisplayPage = () => {
        if (!currentSection) return pageNumber;
        const section = sections[currentSection];
        return pageNumber - section.start + 1;
    };

    const getCurrentMaxPages = () => {
        if (!currentSection) return numPages;
        const section = sections[currentSection];
        return section.displayEnd;
    };

    const goToSection = (sectionName, e) => {
        e.preventDefault();
        setCurrentSection(sectionName === currentSection ? null : sectionName);
        const section = sections[sectionName];
        if (sectionName === currentSection) {
            setPageNumber(1);
        } else {
            setPageNumber(section.start);
        }
    };

    return (
        <div className="container">
            <div className="background-image"></div>
            <header>
                <h1>
                    D<span className="greek-a">Δ</span>SKS
                </h1>
                <h2>GREEK GRILL</h2>
                <h3 className="subtitle">Desserts</h3>
            </header>

            <div className="pastries-link">
                <a
                    href="https://www.facebook.com/phanspastryboutique/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pastries-button"
                >
                    Check Out Phan's Desserts
                </a>
            </div>

            <div className="dessert-navigation">
                <button 
                    onClick={(e) => goToSection('cakes', e)}
                    className={`dessert-nav-button ${currentSection === 'cakes' ? 'active' : ''}`}
                >
                    Cakes
                </button>
                <button 
                    onClick={(e) => goToSection('pastries', e)}
                    className={`dessert-nav-button ${currentSection === 'pastries' ? 'active' : ''}`}
                >
                    Pastries
                </button>
            </div>

            <div className="pdf-container">
                <Document
                    file="/DessertMenu.pdf" // Use absolute path from public folder
                    options={{
                        cMapUrl: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/cmaps/`,
                        cMapPacked: true
                    }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                    loading={<div className="loading">Loading menu...</div>}
                    error={
                        <div className="error">
                            Error loading menu: {error ? error.message : 'Please try again later.'}
                            <br />
                            <small>If this persists, please contact support.</small>
                        </div>
                    }
                >
                    <Page 
                        pageNumber={pageNumber} 
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        className="pdf-page"
                        scale={scale}
                    />
                </Document>
            </div>
        
            {numPages > 1 && (
                <div className="page-controls">
                    <button 
                        onClick={(e) => changePage(getCurrentDisplayPage() - 1, e)}
                        disabled={currentSection ? 
                            pageNumber <= sections[currentSection].start :
                            pageNumber <= 1}
                        aria-label="Previous page"
                    >
                        ⟪
                    </button>
                    <span>Page {getCurrentDisplayPage()} of {getCurrentMaxPages()}</span>
                    <button 
                        onClick={(e) => changePage(getCurrentDisplayPage() + 1, e)}
                        disabled={currentSection ?
                            pageNumber >= sections[currentSection].end :
                            pageNumber >= numPages}
                        aria-label="Next page"
                    >
                        ⟫
                    </button>
                </div>
            )}
        </div>
    );
}

export default Desserts; 