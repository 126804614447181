import React, { useState, useEffect } from 'react';
import '../css/Catering.css';
import '../styles.css';
import emailjs from '@emailjs/browser';

function Catering() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [formStatus, setFormStatus] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [packages, setPackages] = useState({
        souvlaki: { count: 0, meat: 'chicken' },
        gyro: { count: 0, meat: 'beef-lamb' },
        gyroCustom: {
            count: 0,
            meats: {
                'beef-lamb': 0,
                'chicken': 0,
                'philly': 0,
                'falafel': 0,
                'veggie': 0,
                'vegan': 0
            }
        }
    });
    const [hasDelivery, setHasDelivery] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [formProgress, setFormProgress] = useState({
        items: false,
        contact: false,
        payment: false
    });

    useEffect(() => {
        // Update progress when items are selected
        setFormProgress(prev => ({
            ...prev,
            items: selectedItems.length > 0
        }));
    }, [selectedItems]);

    const handleContactInfoChange = (e) => {
        const nameField = document.getElementById('name');
        const phoneField = document.getElementById('phone');
        const emailField = document.getElementById('email');
        
        const hasName = nameField && nameField.value.trim() !== '';
        const hasPhone = phoneField && phoneField.value.trim() !== '' && !phoneError;
        const hasEmail = emailField && emailField.value.trim() !== '';
        
        setFormProgress(prev => ({
            ...prev,
            contact: hasName && hasPhone && hasEmail
        }));
    };

    const handlePaymentMethodChange = (e) => {
        setFormProgress(prev => ({
            ...prev,
            payment: e.target.value !== ''
        }));
    };

    const meatOptions = {
        souvlaki: [
            { value: 'chicken', label: 'Chicken' },
            { value: 'pork', label: 'Pork' }
        ],
        gyro: [
            { value: 'beef-lamb', label: 'Beef & Lamb' },
            { value: 'chicken', label: 'Chicken' },
            { value: 'philly', label: 'Philly Steak' },
            { value: 'falafel', label: 'Falafel' },
            { value: 'veggie', label: 'Veggie' },
            { value: 'vegan', label: 'Vegan' }
        ]
    };

    const handlePackageChange = (type, value) => {
        setPackages(prev => ({
            ...prev,
            [type]: {
                ...prev[type],
                count: Math.max(0, parseInt(value) || 0)
            }
        }));
    };

    const handleMeatChoice = (type, meat) => {
        setPackages(prev => ({
            ...prev,
            [type]: {
                ...prev[type],
                meat: meat
            }
        }));
    };

    const handleAddToOrder = (type) => {
        const pkg = packages[type];
        if (pkg.count > 0) {
            if (type === 'gyroCustom') {
                const totalMeats = Object.values(pkg.meats).reduce((sum, count) => sum + count, 0);
                if (totalMeats % 12 !== 0) {
                    alert('Please adjust your selections to make complete packages of 12 gyros each.');
                    return;
                }
                
                // Check if an identical custom order already exists
                const existingCustomIndex = selectedItems.findIndex(item => {
                    if (!item.isCustom) return false;
                    // Compare each meat count to see if it's the same configuration
                    return Object.entries(item.meatCounts).every(
                        ([meat, count]) => count === pkg.meats[meat]
                    );
                });

                if (existingCustomIndex !== -1) {
                    // Update quantity of existing custom order
                    setSelectedItems(prev => prev.map((item, index) => 
                        index === existingCustomIndex 
                            ? { ...item, quantity: (item.quantity || 1) + pkg.count }
                            : item
                    ));
                } else {
                    // Add new custom order
                    const newItems = [{
                        id: Date.now() + Math.random(),
                        type: 'gyro',
                        isCustom: true,
                        meatCounts: {...pkg.meats},
                        packageType: 'Gyro',
                        meatLabel: 'Custom',
                        quantity: pkg.count
                    }];
                    setSelectedItems(prev => [...prev, ...newItems]);
                }
            } else {
                // Check if item already exists
                const existingItemIndex = selectedItems.findIndex(
                    item => item.type === type && item.meat === pkg.meat && !item.isCustom
                );

                if (existingItemIndex !== -1) {
                    // Update quantity of existing item
                    setSelectedItems(prev => prev.map((item, index) => 
                        index === existingItemIndex 
                            ? { ...item, quantity: (item.quantity || 1) + pkg.count }
                            : item
                    ));
                } else {
                    // Add new item with quantity
                    const newItem = {
                        id: Date.now() + Math.random(),
                        type: type,
                        meat: pkg.meat,
                        packageType: type === 'souvlaki' ? 'Souvlaki' : 'Gyro',
                        meatLabel: meatOptions[type].find(opt => opt.value === pkg.meat).label,
                        quantity: pkg.count
                    };
                    setSelectedItems(prev => [...prev, newItem]);
                }
            }
            // Reset counter after adding to order
            handlePackageChange(type, 0);
            if (type === 'gyroCustom') {
                setPackages(prev => ({
                    ...prev,
                    gyroCustom: {
                        ...prev.gyroCustom,
                        meats: Object.fromEntries(
                            Object.keys(prev.gyroCustom.meats).map(key => [key, 0])
                        )
                    }
                }));
            }
        }
    };

    const handleCustomGyroMeatChange = (meat, value) => {
        const newValue = Math.max(0, parseInt(value) || 0);
        const currentTotal = Object.entries(packages.gyroCustom.meats)
            .reduce((sum, [key, count]) => sum + (key === meat ? 0 : count), 0);
        
        // Calculate what the new total would be
        const newTotal = currentTotal + newValue;
        
        // Update the meat value
        setPackages(prev => {
            const updatedMeats = {
                ...prev.gyroCustom.meats,
                [meat]: newValue
            };
            
            // Calculate total after update
            const totalGyros = Object.values(updatedMeats).reduce((sum, count) => sum + count, 0);
            const packageCount = Math.floor(totalGyros / 12);
            
            // Only allow multiples of 12
            if (totalGyros % 12 !== 0) {
                return {
                    ...prev,
                    gyroCustom: {
                        ...prev.gyroCustom,
                        meats: updatedMeats,
                        count: packageCount
                    }
                };
            }
            
            return {
                ...prev,
                gyroCustom: {
                    ...prev.gyroCustom,
                    meats: updatedMeats,
                    count: packageCount
                }
            };
        });
    };

    const handleRemoveItem = (itemId) => {
        setSelectedItems(prev => prev.filter(item => item.id !== itemId));
    };

    const calculateTotal = () => {
        // Sum up quantities of all items
        const totalQuantity = selectedItems.reduce((sum, item) => sum + (item.quantity || 1), 0);
        const baseTotal = (totalQuantity * 98).toFixed(2);
        const total = hasDelivery ? (baseTotal * 1.2).toFixed(2) : baseTotal;
        return parseFloat(total);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return phoneRegex.test(phone);
    };

    const handlePhoneBlur = (e) => {
        const isValid = validatePhone(e.target.value);
        setPhoneError(!isValid && e.target.value !== '');
    };

    const sendConfirmationEmail = async (formData, selectedItems, total) => {
        // Add console.log to debug
        console.log("Form email:", formData.get('email'));
        
        // Create the email content
        const itemsList = selectedItems
            .map(item => `${item.packageType} - ${item.meatLabel}`)
            .join('\n');
        
        const emailParams = {
            to_email: formData.get('email'),  // Make sure this matches your EmailJS template variable
            to_name: formData.get('name'),
            order_summary: itemsList,
            base_total: `$${(selectedItems.length * 98).toFixed(2)}`,
            delivery_fee: hasDelivery ? `$${(selectedItems.length * 98 * 0.2).toFixed(2)}` : 'N/A',
            final_total: `$${total}`,
            delivery_address: hasDelivery ? formData.get('address') : 'Pickup',
            special_requests: formData.get('message') || 'None',
            payment_method: formData.get('paymentMethod'),
            from_name: 'Dasks Greek Grill Catering',
            reply_to: 'dasksinfo@gmail.com'  // Add this to ensure replies go to Dasks
        };

        // Debug log to see what's being sent
        console.log("Email Parameters:", emailParams);

        try {
            const result = await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                emailParams,
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            );
            console.log("EmailJS Response:", result);
            return true;
        } catch (error) {
            console.error('Email Error:', error);
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedItems.length === 0) {
            setFormStatus('no-packages');
            return;
        }

        const phone = e.target.phone.value;
        if (!validatePhone(phone)) {
            setFormStatus('invalid-phone');
            return;
        }

        const formData = new FormData(e.target);
        const total = calculateTotal();

        // Create a more structured order summary
        const orderSummary = `
=== DASKS CATERING ORDER ===

PACKAGES ORDERED:
${selectedItems.map(item => {
    if (item.isCustom) {
        const meatBreakdown = Object.entries(item.meatCounts)
            .filter(([_, count]) => count > 0)
            .map(([meat, count]) => `  - ${meatOptions.gyro.find(opt => opt.value === meat).label}: ${count}`)
            .join('\n');
        return `• Custom Gyro Package (Quantity: ${item.quantity})\n${meatBreakdown}`;
    } else {
        return `• ${item.packageType} Package (Quantity: ${item.quantity})\n  - Meat Choice: ${item.meatLabel}`;
    }
}).join('\n')}

ORDER DETAILS:
• Base Total: $${(selectedItems.reduce((sum, item) => sum + ((item.quantity || 1) * 98), 0)).toFixed(2)}
• Delivery: ${hasDelivery ? 'Yes' : 'No'}
• Delivery Fee: ${hasDelivery ? `$${(selectedItems.reduce((sum, item) => sum + ((item.quantity || 1) * 98), 0) * 0.2).toFixed(2)}` : 'N/A'}
• Final Total: $${total.toFixed(2)}
`;

        // Add structured data to formData
        formData.append('_subject', 'New Catering Order - DASKS Greek Grill');
        formData.append('Order_Summary', orderSummary);
        formData.append('Total_Packages', selectedItems.reduce((sum, item) => sum + (item.quantity || 1), 0));
        formData.append('Delivery_Required', hasDelivery ? 'Yes' : 'No');
        formData.append('Payment_Method', formData.get('paymentMethod'));

        try {
            // Send to Formspree with only allowed headers
            const response = await fetch("https://formspree.io/f/mqaevylk", {
                method: "POST",
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            });
            
            if (response.ok) {
                const emailSent = await sendConfirmationEmail(formData, selectedItems, total);
                
                if (emailSent) {
                    setFormStatus('success');
                    e.target.reset();
                    setSelectedItems([]);
                    setHasDelivery(false);
                } else {
                    setFormStatus('email-error');
                }
            } else {
                console.error('Formspree response:', await response.text());
                setFormStatus('error');
            }
        } catch (error) {
            console.error('Form submission error:', error);
            setFormStatus('error');
        }
    };

    return (
        <div className="container">
            <div className="background-image"></div>
            <header>
                <h1>
                    D<span className="greek-a">Δ</span>SKS
                </h1>
                <h2>GREEK GRILL</h2>
                <h3 className="subtitle">Catering Services</h3>
            </header>

            <div className="info-card">
                <h3>Catering Package Information</h3>
                <p className="larger-text">All of our catering packages are priced at $98 each and are designed to serve 6-8 people.</p>
                <p className="highlight-text">Our catering service is perfect for office events, parties, and gatherings. Catering available seven days a week!</p>
            </div>

            <div className="catering-packages">
                <div className="package">
                    <h3>Souvlaki</h3>
                    <p className="description larger-text">
                        Includes your choice of Souvlaki Protein, rice, yogurt, Pita Bread, and Salad
                    </p>
                    <div className="meat-choice">
                        <select 
                            value={packages.souvlaki.meat}
                            onChange={(e) => handleMeatChoice('souvlaki', e.target.value)}
                            className="meat-select"
                        >
                            {meatOptions.souvlaki.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="package-controls">
                        <div className="package-quantity">
                            <button 
                                className="quantity-button"
                                onClick={() => handlePackageChange('souvlaki', packages.souvlaki.count - 1)}
                            >
                                -
                            </button>
                            <input
                                type="number"
                                min="0"
                                value={packages.souvlaki.count}
                                onChange={(e) => handlePackageChange('souvlaki', e.target.value)}
                            />
                            <button 
                                className="quantity-button"
                                onClick={() => handlePackageChange('souvlaki', packages.souvlaki.count + 1)}
                            >
                                +
                            </button>
                        </div>
                        <button 
                            className="add-to-order-button"
                            onClick={() => handleAddToOrder('souvlaki')}
                            disabled={packages.souvlaki.count === 0}
                        >
                            Add to Order
                        </button>
                    </div>
                </div>

                <div className="package">
                    <h3>Gyro</h3>
                    <p className="description larger-text">
                        Includes Gyros with your choice of Protein, Rice, and Salad
                    </p>
                    <div className="meat-choice">
                        <select 
                            value={packages.gyro.meat}
                            onChange={(e) => handleMeatChoice('gyro', e.target.value)}
                            className="meat-select"
                        >
                            {meatOptions.gyro.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="package-controls">
                        <div className="package-quantity">
                            <button 
                                className="quantity-button"
                                onClick={() => handlePackageChange('gyro', packages.gyro.count - 1)}
                            >
                                -
                            </button>
                            <input
                                type="number"
                                min="0"
                                value={packages.gyro.count}
                                onChange={(e) => handlePackageChange('gyro', e.target.value)}
                            />
                            <button 
                                className="quantity-button"
                                onClick={() => handlePackageChange('gyro', packages.gyro.count + 1)}
                            >
                                +
                            </button>
                        </div>
                        <button 
                            className="add-to-order-button"
                            onClick={() => handleAddToOrder('gyro')}
                            disabled={packages.gyro.count === 0}
                        >
                            Add to Order
                        </button>
                    </div>
                </div>

                <div className="package">
                    <h3>Custom Gyro Package</h3>
                    <p className="description larger-text">
                        Create a custom mix of gyros (total must equal 12)
                    </p>
                    <div className="custom-meat-choices">
                        {meatOptions.gyro.map(option => (
                            <div key={option.value} className="custom-meat-choice">
                                <label>{option.label}</label>
                                <div className="custom-quantity-controls">
                                    <button 
                                        className="quantity-button"
                                        onClick={() => handleCustomGyroMeatChange(
                                            option.value, 
                                            (packages.gyroCustom.meats[option.value] || 0) - 1
                                        )}
                                    >
                                        -
                                    </button>
                                    <input
                                        type="number"
                                        min="0"
                                        max="12"
                                        value={packages.gyroCustom.meats[option.value]}
                                        onChange={(e) => handleCustomGyroMeatChange(option.value, e.target.value)}
                                    />
                                    <button 
                                        className="quantity-button"
                                        onClick={() => handleCustomGyroMeatChange(
                                            option.value, 
                                            (packages.gyroCustom.meats[option.value] || 0) + 1
                                        )}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        ))}
                        <div className="total-count">
                            Total: {Object.values(packages.gyroCustom.meats).reduce((sum, count) => sum + count, 0)}/12
                            {Object.values(packages.gyroCustom.meats).reduce((sum, count) => sum + count, 0) > 0 && 
                                Object.values(packages.gyroCustom.meats).reduce((sum, count) => sum + count, 0) % 12 !== 0 && (
                                    <div className="total-count-warning">
                                        Please select gyros in multiples of 12 to create complete packages
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="package-controls">
                        <div className="package-quantity">
                            <button 
                                className="quantity-button"
                                onClick={() => handlePackageChange('gyroCustom', packages.gyroCustom.count - 1)}
                            >
                                -
                            </button>
                            <input
                                type="number"
                                min="0"
                                value={packages.gyroCustom.count}
                                onChange={(e) => handlePackageChange('gyroCustom', e.target.value)}
                            />
                            <button 
                                className="quantity-button"
                                onClick={() => handlePackageChange('gyroCustom', packages.gyroCustom.count + 1)}
                            >
                                +
                            </button>
                        </div>
                        <button 
                            className="add-to-order-button"
                            onClick={() => handleAddToOrder('gyroCustom')}
                            disabled={packages.gyroCustom.count === 0}
                        >
                            Add to Order
                        </button>
                    </div>
                </div>
            </div>

            {selectedItems.length > 0 && (
                <div className="selected-items">
                    <h3>Your Order</h3>
                    {selectedItems.map(item => (
                        <div key={item.id} className="selected-item">
                            <span>
                                {item.packageType} - {item.meatLabel}
                                {item.quantity > 1 && ` x ${item.quantity}`}
                                {item.isCustom && (
                                    <div className="custom-breakdown">
                                        {Object.entries(item.meatCounts)
                                            .filter(([_, count]) => count > 0)
                                            .map(([meat, count]) => (
                                                <div key={meat}>
                                                    {meatOptions.gyro.find(opt => opt.value === meat).label}: {count}
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </span>
                            <button 
                                className="remove-item-button"
                                onClick={() => handleRemoveItem(item.id)}
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                </div>
            )}

            {selectedItems.length > 0 && (
                <div className="total-cost">
                    Base Total: ${(selectedItems.reduce((sum, item) => sum + ((item.quantity || 1) * 98), 0)).toFixed(2)}
                    {hasDelivery && (
                        <div className="transportation-fee">
                            Transportation Fee (20%): ${(selectedItems.reduce((sum, item) => 
                                sum + ((item.quantity || 1) * 98), 0) * 0.2).toFixed(2)}
                        </div>
                    )}
                    <div className="final-total">
                        Final Total: ${calculateTotal().toFixed(2)}
                    </div>
                </div>
            )}

            <div className="catering-form-container">
                <h3>Catering Request</h3>
                
                <div className="form-completion-message">
                    <p className="larger-text">Please complete the form below to submit your catering request. We will contact you to confirm your order. Thanks for supporting our local business!</p>
                </div>
                
                <div className="form-progress-tracker">
                    <div className={`progress-step ${formProgress.items ? 'completed' : ''}`}>
                        <div className="step-circle">1</div>
                        <div className="step-label">Add Items</div>
                    </div>
                    <div className="progress-bar"></div>
                    <div className={`progress-step ${formProgress.contact ? 'completed' : ''}`}>
                        <div className="step-circle">2</div>
                        <div className="step-label">Contact Info</div>
                    </div>
                    <div className="progress-bar"></div>
                    <div className={`progress-step ${formProgress.payment ? 'completed' : ''}`}>
                        <div className="step-circle">3</div>
                        <div className="step-label">Payment</div>
                    </div>
                </div>
                
                <form onSubmit={handleSubmit} className="catering-form">
                    <div className="form-group">
                        <label htmlFor="paymentMethod">Payment Method</label>
                        <select 
                            id="paymentMethod"
                            name="paymentMethod" 
                            required
                            onChange={handlePaymentMethodChange}
                        >
                            <option value="">Select Payment Method</option>
                            <option value="instore">Pay In-Store</option>
                            <option value="phone">Pay Over Phone</option>
                        </select>
                    </div>

                    <div className="form-group checkbox-group">
                        <label>
                            <input 
                                type="checkbox"
                                name="delivery"
                                checked={hasDelivery}
                                onChange={(e) => setHasDelivery(e.target.checked)}
                            />
                            Delivery and Setup (20% Transportation Fee)
                        </label>
                    </div>

                    {hasDelivery && (
                        <div className="delivery-address-section">
                            <div className="form-group">
                                <label htmlFor="address">Full Address</label>
                                <textarea 
                                    id="address"
                                    name="address" 
                                    placeholder="Enter your complete address including street, city, state, and ZIP code" 
                                    required={hasDelivery}
                                    rows="3"
                                ></textarea>
                            </div>

                            <div className="form-group">
                                <label htmlFor="delivery-instructions">Delivery Instructions (Optional)</label>
                                <textarea 
                                    id="delivery-instructions"
                                    name="delivery-instructions" 
                                    placeholder="Any specific delivery instructions?" 
                                    rows="2"
                                ></textarea>
                            </div>
                        </div>
                    )}

                    <div className="form-group">
                        <label htmlFor="name">Full Name</label>
                        <input 
                            id="name"
                            type="text" 
                            name="name" 
                            placeholder="Your Name" 
                            required 
                            onChange={handleContactInfoChange}
                        />
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input 
                            id="phone"
                            type="tel" 
                            name="phone" 
                            placeholder="(xxx) xxx-xxxx" 
                            required 
                            onBlur={handlePhoneBlur}
                            onChange={(e) => {
                                setPhoneError(false);
                                handleContactInfoChange(e);
                            }}
                        />
                        {phoneError && (
                            <div className="form-message error">
                                Please enter a valid phone number
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <input 
                            id="email"
                            type="email" 
                            name="email" 
                            placeholder="Email Address" 
                            required 
                            onChange={handleContactInfoChange}
                        />
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="message">Special Requests</label>
                        <textarea 
                            id="message"
                            name="message" 
                            placeholder="Any special requests or questions?" 
                            rows="4"
                        ></textarea>
                    </div>

                    <button type="submit" className="submit-button">
                        Submit Catering Request
                    </button>
                    
                    {formStatus === 'success' && (
                        <div className="form-message success">
                            Thank you for your inquiry! We'll get back to you soon.
                        </div>
                    )}
                    {formStatus === 'error' && (
                        <div className="form-message error">
                            Something went wrong. Please try again later.
                        </div>
                    )}
                    {formStatus === 'no-packages' && (
                        <div className="form-message error">
                            Please select at least one package before submitting.
                        </div>
                    )}
                    {formStatus === 'email-error' && (
                        <div className="form-message error">
                            Order received but confirmation email failed to send. Please check your email address.
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
}

export default Catering; 