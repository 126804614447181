import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
// import "./styles.css"; // Import the css file for styling
import './styles.css';  // Single clean import
import storefront from './images/Capture2.png';
import About from './components/About';
import Menu from './components/Menu';
import Desserts from './components/Desserts';
import Catering from './components/Catering';
import Footer from './components/Footer';
// Import background image
import backgroundImage from './images/Capture2.png';

// ScrollToTop component
function ScrollToTop() {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function Home() {
  return (
    <div className="container">
      <header>
        <h1>
          D<span className="greek-a">Δ</span>SKS
        </h1>
        <h2>GREEK GRILL</h2>
        <h3 className="subtitle">Home of Salt Lake's Best Gyro</h3>
      </header>
      
      <div className="quote">
        <p>
          "Soft, warm pita filled with copious amounts of well-seasoned meat, small onion squares, liberal saucing of tangy, great-tasting tzatziki. Glorious garlic breath afterwards...in a good way. Can't ask for better."
        </p>
        <cite>- Lesli J. Neilson, Salt Lake Tribune</cite>
      </div>

      <div className="order-online">
        <a
          href="https://direct.chownow.com/order/5373/locations/7035"
          target="_blank"
          rel="noopener noreferrer"
        >
          ORDER ONLINE
        </a>
      </div>

      <div className="info">
        <div className="location">
          <h3>LOCATION</h3>
          <a 
            href="https://maps.google.com/?q=6522+Big+Cottonwood+Canyon+Rd+Holladay+UT+84121" 
            target="_blank" 
            rel="noopener noreferrer"
            className="address-link"
          >
            <p className="address">6522 Big Cottonwood Canyon Rd.</p>
            <p className="address">Holladay, UT 84121</p>
          </a>
          <p>----------------------------------</p>
          <p>Tel: (801) 733-5010</p>
          <p className="email">Email: dasksinfo@gmail.com</p>
        </div>

        <div className="hours">
          <h3>HOURS</h3>
          <p>Monday - Friday:</p>
          <p>10:30 AM - 7:30 PM</p>
          <p>----------------------------------</p>
          <p>Saturday & Sunday:</p>
          <p>Catering & Onsite Private Events Only</p>
        </div>
      </div>

      <div className="catering-promo">
        <Link to="/catering" className="catering-button">
          NEW! ORDER CATERING ONLINE
        </Link>
        <p className="catering-subtitle">Highest Quality Catering according to EzCater!</p>
      </div>

      <div className="coming-soon">
        <p>New Website Same Great Food!</p>
      </div>
    </div>
  );
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavClick = () => {
    setIsMenuOpen(false);
    window.scrollTo(0, 0);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('.responsive-nav')) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isMenuOpen]);

  return (
    <Router>
      <div className="app-container">
        <div 
          className="background-image"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />
        <ScrollToTop />
        <nav className="responsive-nav">
          <div className="nav-header">
            <h1 className="mobile-logo">
              D<span className="greek-a">Δ</span>SKS
            </h1>
            <button 
              className="hamburger-menu" 
              onClick={(e) => {
                e.stopPropagation();
                setIsMenuOpen(!isMenuOpen);
              }}
              aria-label="Toggle Navigation Menu"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          
          <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
            <Link to="/" onClick={handleNavClick}>Home</Link>
            <Link to="/menu" onClick={handleNavClick}>Menu</Link>
            <Link to="/catering" onClick={handleNavClick}>Catering</Link>
            <Link to="/desserts" onClick={handleNavClick}>Desserts</Link>
            <Link to="/about" onClick={handleNavClick}>About</Link>
          </div>
        </nav>
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/desserts" element={<Desserts />} />
          <Route path="/catering" element={<Catering />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App; 