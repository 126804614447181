import React from 'react';
import '../css/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="contact-info">
          <h4>Contact Us</h4>
            <p>6522 Big Cottonwood Canyon Rd.</p>
            <p>Holladay, UT 84121</p>
          <p>Phone: (801) 733-3466</p>
          <p>Email: dasksinfo@gmail.com</p>
        </div>
        <div className="hours-info">
          <h4>Hours</h4>
          <p>Monday - Friday: 10:30 AM - 7:30 PM</p>
          <p>Saturday & Sunday: Catering & Onsite Private Events Only</p>
        </div>
        <div className="footer-attribution">
          <a 
            href="https://haiconsultingservices.com" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            Website Built by <span>HAI Solutions</span>
          </a>
          <p className="copyright">© 2025 Dasks Greek Grill. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer; 