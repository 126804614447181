import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Menu.css';
import '../styles.css';
import { Document, Page, pdfjs } from 'react-pdf';
// Set the worker source
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Define image paths
const falafelGyroImage = process.env.PUBLIC_URL + '/images/menu/Falafel Gyro-1.jpg';
const falafelGyroPlateImage = process.env.PUBLIC_URL + '/images/menu/Falafel Gyro Plate.jpg';
const hummusImage = process.env.PUBLIC_URL + '/images/menu/Hummus.jpg';
const dolmathesImage = process.env.PUBLIC_URL + '/images/menu/Dolmathes.jpg';
const spanakopitaImage = process.env.PUBLIC_URL + '/images/menu/Spanakopita Plate.jpg';
const doubleDasksBurgerImage = process.env.PUBLIC_URL + '/images/menu/Double Dasks Burger.jpg';
const chickenOrzoSoupImage = process.env.PUBLIC_URL + '/images/menu/Chicken Orzo Soup.jpg';
const miditranianBarleySoupImage = process.env.PUBLIC_URL + '/images/menu/Miditranian Barley Soup.jpg';

function Menu() {
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, {
      threshold: 0.1,
      rootMargin: '0px'
    });

    // Observe all animated elements
    document.querySelectorAll('.slide-in-left, .slide-in-right').forEach((el, index) => {
      el.style.setProperty('--order', index); // Set order for stagger effect
      observer.observe(el);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="menu-container">
      <div className="background-image"></div>
      <header className="slide-in-left">
        <h1>
          D<span className="greek-a">Δ</span>SKS
        </h1>
        <h2>GREEK GRILL</h2>
        <h3 className="subtitle">Our Menu</h3>
      </header>

      {/* Featured Items Gallery */}
      <div className="featured-items slide-in-right">
        <h3>Featured Items</h3>
        <div className="featured-gallery-container">
          <div className="featured-gallery">
            {/* First set of items */}
            <div className="featured-item">
              <div className="featured-image-container">
                <img src={falafelGyroImage} alt="Falafel Gyro" className="featured-image" />
              </div>
              <h4>Falafel Gyro</h4>
            </div>
            <div className="featured-item">
              <div className="featured-image-container">
                <img src={falafelGyroPlateImage} alt="Falafel Plate" className="featured-image" />
              </div>
              <h4>Falafel Plate</h4>
            </div>
            <div className="featured-item">
              <div className="featured-image-container">
                <img src={doubleDasksBurgerImage} alt="Double Dasks Burger" className="featured-image" />
              </div>
              <h4>Double Dasks Burger</h4>
            </div>
            <div className="featured-item">
              <div className="featured-image-container">
                <img src={hummusImage} alt="Hummus" className="featured-image" />
              </div>
              <h4>Hummus</h4>
            </div>
            <div className="featured-item">
              <div className="featured-image-container">
                <img src={spanakopitaImage} alt="Spanakopita" className="featured-image" />
              </div>
              <h4>Spanakopita</h4>
            </div>
            
            {/* Duplicate items for continuous scrolling */}
            <div className="featured-item">
              <div className="featured-image-container">
                <img src={falafelGyroImage} alt="Falafel Gyro" className="featured-image" />
              </div>
              <h4>Falafel Gyro</h4>
            </div>
            <div className="featured-item">
              <div className="featured-image-container">
                <img src={falafelGyroPlateImage} alt="Falafel Plate" className="featured-image" />
              </div>
              <h4>Falafel Plate</h4>
            </div>
            <div className="featured-item">
              <div className="featured-image-container">
                <img src={doubleDasksBurgerImage} alt="Double Dasks Burger" className="featured-image" />
              </div>
              <h4>Double Dasks Burger</h4>
            </div>
            <div className="featured-item">
              <div className="featured-image-container">
                <img src={hummusImage} alt="Hummus" className="featured-image" />
              </div>
              <h4>Hummus</h4>
            </div>
            <div className="featured-item">
              <div className="featured-image-container">
                <img src={spanakopitaImage} alt="Spanakopita" className="featured-image" />
              </div>
              <h4>Spanakopita</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="section-divider">
        <div className="divider-line"></div>
        <div className="divider-icon">
          <span className="greek-a">Δ</span>
        </div>
        <div className="divider-line"></div>
      </div>

      <div className="menu-content">
        {/* Gyros Section */}
        <div className="menu-section full-width slide-in-left">
          <h3>Gyros</h3>
          <div className="menu-notes">
            <p>Gluten-free lettuce wrap option available</p>
            <p>Combo includes: Entrée + one side + med drink</p>
            <p>Plate includes: Entrée + choice of two sides</p>
            <p>Side dishes include: Salad, rice, fries</p>
          </div>
          <div className="price-header">
            <span>Sandwich</span>
            <span>Plate/Combo</span>
          </div>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>Gyro (Beef & Lamb)</h4>
                <div className="price-columns">
                  <span className="price">$10.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Pita bread, gyro meat, tomatoes, onions, red or white sauce</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Falafel Vegan</h4>
                <div className="price-columns">
                  <span className="price">$10.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Pita bread, garbanzo beans, onions, lettuce, diced cucumbers, tomatoes, yogurt or hummus</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Falafel Plate</h4>
                <div className="price-columns">
                  <span className="price"></span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Falafel with choice of two sides, served with pita bread</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Chicken Gyro</h4>
                <div className="price-columns">
                  <span className="price">$10.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Pita bread, grilled chicken breast, tomatoes, onions, lettuce, red or white sauce</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Philly Gyro</h4>
                <div className="price-columns">
                  <span className="price">$10.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Pita bread, Philly steak, yogurt, swiss cheese, grilled peppers, and onions</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Veggie Gyro</h4>
                <div className="price-columns">
                  <span className="price">$8.95</span>
                  <span className="price">$13.95</span>
                </div>
              </div>
              <p className="description">Pita bread, tomatoes, cucumbers, lettuce, onions, yogurt sauce, feta, house dressing</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Vegan Gyro</h4>
                <div className="price-columns">
                  <span className="price">$7.95</span>
                  <span className="price">$12.95</span>
                </div>
              </div>
              <p className="description">Pita bread, hummus sauce, onions, lettuce, tomato, cucumbers, house dressing</p>
            </div>
          </div>
        </div>

        {/* Souvlaki Section */}
        <div className="menu-section full-width slide-in-right">
          <h3>Souvlaki</h3>
          <div className="menu-notes">
            <p>Singles include: One souvlaki, one side, pita bread</p>
            <p>Plate includes: Two souvlaki, two sides, pita bread</p>
          </div>
          <div className="price-header">
            <span>Single</span>
            <span>Plate</span>
          </div>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>Pork Souvlaki</h4>
                <div className="price-columns">
                  <span className="price">$10.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Diced pork grilled & marinated</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Chicken Souvlaki</h4>
                <div className="price-columns">
                  <span className="price">$10.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Chicken breast marinated, grilled and diced</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Combo Souvlaki</h4>
                <div className="price-columns">
                  <span className="price"></span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Choice of any two souvlaki</p>
            </div>
          </div>
        </div>

        {/* Salads Section */}
        <div className="menu-section full-width slide-in-left">
          <h3>Salads</h3>
          <div className="menu-notes">
            <p>Salad includes pita bread</p>
          </div>
          <div className="price-header">
            <span>Regular</span>
            <span>Large</span>
          </div>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>Greek Salad</h4>
                <div className="price-columns">
                  <span className="price">$8.95</span>
                  <span className="price">$11.95</span>
                </div>
              </div>
              <p className="description">Iceberg salad mix, tomatoes, cucumbers, feta cheese, Greek olives, house dressing</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Falafel Salad</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Ground seasoned garbanzo beans served atop our Greek Salad</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Grilled Chicken Salad</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Grilled all white chicken breast served atop our Greek Salad</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Gyro Salad</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Shaved gyro meat served atop our Greek Salad</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Pork Salad</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Marinated grilled pork served atop our Greek Salad</p>
            </div>
          </div>
        </div>

        {/* Sandwiches & Burgers Section */}
        <div className="menu-section full-width slide-in-right">
          <h3>Sandwiches & Burgers</h3>
          <div className="price-header">
            <span>A-La Carte</span>
            <span>Plate/Combo</span>
          </div>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>DASKS Burger</h4>
                <div className="price-columns">
                  <span className="price">$9.95</span>
                  <span className="price">$14.95</span>
                </div>
              </div>
              <p className="description">Toasted hamburger bun topped with a charbroiled beef patty, lettuce, tomatoes, onions and fry sauce</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Double Dasks Burger</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                  <span className="price">$16.95</span>
                </div>
              </div>
              <p className="description">Toasted hamburger bun topped with two charbroiled beef patties, lettuce, tomatoes, onions and fry sauce</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Vegan Burger</h4>
                <div className="price-columns">
                  <span className="price">$9.95</span>
                  <span className="price">$14.95</span>
                </div>
              </div>
              <p className="description">Toasted hamburger bun smeared with hummus sauce topped with a vegan burger patty, lettuce, tomato and onion</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Gyro Burger</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                  <span className="price">$16.95</span>
                </div>
              </div>
              <p className="description">Charbroiled beef patty & grilled gyro meat on a bed of lettuce, tomatoes, and onions with white sauce</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Pastrami Burger</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                  <span className="price">$16.95</span>
                </div>
              </div>
              <p className="description">Charbroiled beef patty topped with pastrami, lettuce, tomato, onions and fry sauce on a toasted bun</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Bacon Burger</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                  <span className="price">$16.95</span>
                </div>
              </div>
              <p className="description">Charbroiled beef patty, topped with bacon, lettuce, tomatoes, onions and fry sauce on a toasted bun</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Breaded Chicken Sandwich</h4>
                <div className="price-columns">
                  <span className="price">$9.95</span>
                  <span className="price">$14.95</span>
                </div>
              </div>
              <p className="description">Deep fried breaded chicken patty atop a toasted bun with fry sauce, lettuce, tomato and onion</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Grilled Chicken Sandwich</h4>
                <div className="price-columns">
                  <span className="price">$9.95</span>
                  <span className="price">$14.95</span>
                </div>
              </div>
              <p className="description">Toasted bun topped with fry sauce, lettuce, tomato, onion and grilled chicken</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Fish Fillet Sandwich</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                  <span className="price">$16.95</span>
                </div>
              </div>
              <p className="description">Deep fried fillet of fish atop a toasted bun with tartar sauce, lettuce, tomato and onion</p>
            </div>
            <div className="menu-item special-note">
              <p>Add Bacon $3.00 | Add Cheese $0.75</p>
            </div>
          </div>
        </div>

        {/* Rice Bowls Section */}
        <div className="menu-section full-width slide-in-left">
          <h3>Rice Bowls</h3>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>Chicken Rice Bowl</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Gyro Rice Bowl</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Falafel Rice Bowl</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Philly Rice Bowl</h4>
                <div className="price-columns">
                  <span className="price">$11.95</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* House Specialties Section */}
        <div className="menu-section full-width slide-in-right">
          <h3>House Specialties</h3>
          <div className="price-header">
            <span>A-La Carte</span>
            <span>Plate/Combo</span>
          </div>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>Tyropita (Cheese Pie)</h4>
                <div className="price-columns">
                  <span className="price">$8.95</span>
                  <span className="price">$14.95</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Spanakopita (Spinach Pie)</h4>
                <div className="price-columns">
                  <span className="price">$8.95</span>
                  <span className="price">$14.95</span>
                </div>
              </div>
              <p className="description">Phyllo pastry filled with spinach and feta cheese</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Dolmathes (Stuffed Grape Leaves)</h4>
                <div className="price-columns">
                  <span className="price">$10.95</span>
                  <span className="price">$15.95</span>
                </div>
              </div>
              <p className="description">Grape leaves stuffed with seasoned rice, served with tzatziki sauce</p>
            </div>
          </div>
        </div>

        {/* Value Menu Section */}
        <div className="menu-section full-width slide-in-left">
          <h3>Value Menu</h3>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>Junior Gyro</h4>
                <div className="price-columns">
                  <span className="price">$5.95</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Chicken Nuggets</h4>
                <div className="price-columns">
                  <span className="price">$5.95</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Value Chicken/Lamb Salad</h4>
                <div className="price-columns">
                  <span className="price">$8.95</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Cheese Pizza</h4>
                <div className="price-columns">
                  <span className="price">$7.95</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Chicken and Rice or Fries</h4>
                <div className="price-columns">
                  <span className="price">$8.95</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Corn Dog</h4>
                <div className="price-columns">
                  <span className="price">$5.95</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Side Orders Section */}
        <div className="menu-section full-width slide-in-right">
          <h3>Side Orders</h3>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>Hummus</h4>
                <div className="price-columns">
                  <span className="price">$9.95</span>
                </div>
              </div>
              <p className="description">Pureed chickpeas, tahini, lemon juice, olive oil, served with pita bread</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Side Plate (rice, salad)</h4>
                <div className="price-columns">
                  <span className="price">$8.95</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Onion Rings</h4>
                <div className="price-columns">
                  <span className="price">$5.75</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Rice Pilaf</h4>
                <div className="price-columns">
                  <span className="price">$6.25</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Falafel (side)</h4>
                <div className="price-columns">
                  <span className="price">$5.75</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Portion of Meat</h4>
                <div className="price-columns">
                  <span className="price">$5.75</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Zucchini Sticks</h4>
                <div className="price-columns">
                  <span className="price">$5.75</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Spring Rolls (vegan)</h4>
                <div className="price-columns">
                  <span className="price">$5.75</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>French Fries</h4>
                <div className="price-columns">
                  <span className="price">$4.75</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Side Salad</h4>
                <div className="price-columns">
                  <span className="price">$4.75</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Pita Bread, Olives, Feta (each)</h4>
                <div className="price-columns">
                  <span className="price">$1.50</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Extra Sauce (2 oz.)</h4>
                <div className="price-columns">
                  <span className="price">$0.75</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Soups Section */}
        <div className="menu-section full-width slide-in-left">
          <h3>Soups</h3>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>Chicken Orzo Soup</h4>
                <div className="price-columns">
                  <span className="price">$5.95</span>
                </div>
              </div>
              <p className="description">Traditional Greek chicken soup with orzo pasta</p>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Mediterranean Barley Soup</h4>
                <div className="price-columns">
                  <span className="price">$5.95</span>
                </div>
              </div>
              <p className="description">Hearty vegetable soup with barley</p>
            </div>
          </div>
        </div>

        {/* Drinks Section */}
        <div className="menu-section full-width slide-in-left">
          <h3>Drinks</h3>
          <div className="menu-notes">
            <p>Coke, Diet Coke, Sprite, Dr Pepper, Diet Dr Pepper and Lemonade</p>
          </div>
          <div className="price-header three-columns">
            <span>Small</span>
            <span>Medium</span>
            <span>Large</span>
          </div>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>Fountain Drinks</h4>
                <div className="price-columns three-columns drinks">
                  <span className="price">$2.75</span>
                  <span className="price">$2.99</span>
                  <span className="price">$3.29</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Desserts Section */}
        <div className="menu-section full-width slide-in-right">
          <h3>In-Store Desserts</h3>
          <div className="menu-items">
            <div className="menu-item">
              <div className="item-header">
                <h4>Baklava</h4>
                <div className="price-columns">
                  <span className="price">$5.75</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Rice Pudding</h4>
                <div className="price-columns">
                  <span className="price">$5.75</span>
                </div>
              </div>
            </div>
            <div className="menu-item">
              <div className="item-header">
                <h4>Churro</h4>
                <div className="price-columns">
                  <span className="price">$2.75</span>
                </div>
              </div>
            </div>
            <div className="menu-item special-note">
              <p>Check out our <Link to="/desserts" className="dessert-link">Desserts Page</Link> for more options!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu; 