import React from 'react';
import '../styles.css';

function About() {
  return (
    <div className="container">
      <header>
        <h1>
          D<span className="greek-a">Δ</span>SKS
        </h1>
        <h2>GREEK GRILL</h2>
        <h3 className="subtitle">Home of Salt Lake's Best Gyro</h3>
      </header>
      
      <div className="background-image"></div>

      <div className="about-section">
        <div className="about-content">
          <p>
            For the past 43 years, Dasks Greek Grill has offered the best Greek cuisine in the Salt Lake Valley. 
            From our original location in Crossroads Mall to our present one in Old Mill Village in Holladay, 
            we have served thousands of Utahns with authentic home-made Greek food experience.
          </p>
          <br />
          <p>
            Dasks team strives for perfection in everything we do. We guarantee only the freshest ingredients 
            in our delicious gyros, souvlaki, burgers and salads. Our staff will make sure that your experience 
            with Dasks is an exceptional one, guaranteeing that you will come back over and over again.
          </p>
          <br />
          <p>
            Our dedication to quality has been noted in Salt Lake Tribune where Dasks gyro was voted 
            the best gyro in Salt Lake Valley.
          </p>
          <br />
          <div className="about-block review">
            <p>
              Lesli J. Neilson, food critic with Salt Lake Tribune, set out on a quest to find the best 
              gyro in Salt Lake Valley. Her quest took her through 35 restaurants and hands down, Dasks delicious 
              gyros, made with care from the best ingredients, have left the competition in the dust. Here is what Neilson had to say about Dasks gyros:
            </p>
            <blockquote>
              "Soft, warm pita filled with copious amounts of well-seasoned meat, small onion squares, liberal 
              saucing of tangy, great-tasting tzatziki. Glorious garlic breath afterwards...in a good way. 
              Can't ask for better."
            </blockquote>
            <a 
              href="https://archive.sltrib.com/story.php?ref=/food/ci_13291082" 
              className="article-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read the full article
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About; 